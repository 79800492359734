// ** Redux Imports
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"

import API from "api/orders"
import {getCustomer} from "../../../users/customers/store"

/*TODO this is not an optimized one*/
// export const getData = createAsyncThunk('appOrders/getData', async (params, {signal, getState}) => {
//     try {
//         const response = await API.listOrders(params);
//         const state = getState();
//         const payments = state.payments;
//
//         if (payments) {
//             const onlyPaymentsForCustomer = payments.customers[params.customerName]
//             const test = onlyPaymentsForCustomer
//                 ?.map(el => {
//                     return el
//                         .ordersList
//                         .reduce((accumulator, order) => {
//                             if (order.amount !== undefined) {
//                                 accumulator.amount.value += order.amount?.value;
//                                 accumulator.amount.exponent = order.amount.exponent;
//                                 accumulator.amount.currencyName = order.amount.currencyName;
//                                 accumulator.status = el.isApproved ? "Approved" : el.status;
//                             }
//
//                             return accumulator;
//                         }, {amount: {value: 0}})
//                 })
//
//             response.ordersList?.map(el => {
//                 const matchingAmount = test?.find(match => match.amount?.value === el.itemsList[0].amount?.value)
//                 if (matchingAmount) {
//                     el.payment_status = matchingAmount.status
//                 }
//             })
//         }
//         if (signal.aborted) {
//             return;
//         }
//
//         return {
//             params,
//             data: response.ordersList,
//             totalRows: response.totalRows,
//         };
//     } catch (error) {
//         console.error('Error in getData thunk:', error);
//         throw error;
//     }
// });

export const getData = createAsyncThunk(
    "appOrders/getData",
    async (params, {signal}) => {
        const response = await API.listOrders(params)
        if (signal.aborted) return
        return {
            params,
            data: response.ordersList,
            totalRows: response.totalRows,
        }
    },
)

export const getOrder = createAsyncThunk(
    "appOrders/getOrder",
    async (name, {dispatch, getState}) => {
        const loaded = getState().orders.data.filter((e) => e.name === name)[0]
        if (loaded) await dispatch(appOrdersSlice.actions.setSelected(loaded))
        return await API.getOrder(name)
    },
)

export const getCustomerOrders = createAsyncThunk(
    "appOrders/getCustomerOrders",
    async (customerName, {getState}) => {
        try {
            const response = await API.getCustomerOrders(customerName)
            const state = getState()
            const result = {}
            result[customerName] = response
            return result
        } catch (err) {
            console.error("Error in getCustomerOrders thunk:", err)
            throw err
        }
    },
)

export const addOrder = createAsyncThunk(
    "appOrders/addOrder",
    async (order, {dispatch}) => {
        await dispatch(appOrdersSlice.actions.setCreating(true))
        const updated = await API.createOrder(order)
        order = {
            ...order,
            ...updated,
        }
        await dispatch(appOrdersSlice.actions.setCreating(false))
        return order
    },
)

export const modifyOrder = createAsyncThunk(
    "appOrders/modifyOrder",
    async (order, {dispatch, getState}) => {
        await dispatch(appOrdersSlice.actions.setUpdating(true))
        const updated = await API.updateOrder(order)
        order = {
            ...order,
            ...updated,
        }
        await dispatch(appOrdersSlice.actions.setUpdating(false))
        await dispatch(appOrdersSlice.actions.setSelected(order))
        await dispatch(getData(getState().orders.params))
        return order
    },
)

export const deleteOrder = createAsyncThunk(
    "appOrders/deleteOrder",
    async (name, {dispatch, getState}) => {
        await API.deleteOrder(name)
        await dispatch(getData(getState().orders.params))
        return name
    },
)
export const moveToArchive = createAsyncThunk(
    "appOrders/moveToArchive",
    async (name, {dispatch, getState}) => {
        await API.moveToArchive(name)
        await dispatch(getData(getState().orders.params))
        return name
    },
)

export const appOrdersSlice = createSlice({
    name: "appOrders",
    initialState: {
        data: [],
        customers: {},
        total: 1,
        loadingParams: {},
        params: {},
        creating: false,
        updating: false,
        orderLoading: false,
    },
    reducers: {
        setCreating: (state, {payload}) => {
            state.creating = payload
        },
        setUpdating: (state, {payload}) => {
            state.updating = payload
        },
        setSelected: (state, {payload}) => {
            state.selectedOrder = payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getData.pending, (state) => {
                state.orderLoading = true
            })
            .addCase(getData.fulfilled, (state, {payload}) => {
                state.data = payload.data
                state.params = payload.params
                state.total = payload.totalRows
                state.orderLoading = false
            })
            .addCase(getCustomer.rejected, (state, {payload}) => {
                state.orderLoading = false
            })
            .addCase(getOrder.fulfilled, (state, {payload}) => {
                state.selectedOrder = payload
            })
            .addCase(getCustomerOrders.fulfilled, (state, {payload}) => {
                state.customers = {
                    ...state.customers,
                    ...payload,
                }
            })
    },
})

export default appOrdersSlice.reducer
