import {ApiUrl, getMetadata} from "../index"
import {
    CreateOrderDiscountTypeRequest,
    CreateOrderRequest,
    CreateOrderServiceTypeRequest,
    DeleteOrderDiscountTypeRequest,
    DeleteOrderRequest,
    DeleteOrderServiceTypeRequest,
    GetOrderDiscountTypeRequest,
    GetOrderRequest,
    GetOrderServiceTypeRequest,
    ListOrderDiscountTypesRequest,
    ListOrderServiceTypesRequest,
    ListOrdersRequest,
    UpdateOrderDiscountTypeRequest,
    UpdateOrderRequest,
    UpdateOrderServiceTypeRequest,
    MoveToArchiveRequest,
} from "./orders_pb"
import {
    Order,
    OrderDiscount,
    OrderDiscountType,
    OrderItem,
    OrderService,
    OrderServiceType,
} from "./order_pb"
import {Money} from "../money_pb"
import {OrdersClient} from "./orders_grpc_web_pb"

let client = new OrdersClient(ApiUrl, null, null)

const order2Model = (order) => {
    let ord = new Order()
    if (order.amount?.value) {
        let amt = new Money()
        amt.setValue(order.amount.value)
        amt.setCurrencyName(order.amount.currencyName)
        amt.setExponent(order.amount.exponent)
        ord.setAmount(amt)
    }
    ord.setCustomerName(order.customerName)
    ord.setStatus(order.status)
    ord.setComment(order.comment)

    let items = []
    if (order.itemsList) {
        order.itemsList.forEach((e) => {
            let item = new OrderItem()
            item.setId(e.id)
            item.setName(e.name)
            item.setDisplayName(e.displayName)
            item.setItemName(e.itemName)
            if (e.amount?.value) {
                let amt = new Money()
                amt.setValue(e.amount.value)
                amt.setCurrencyName(e.amount.currencyName)
                amt.setExponent(e.amount.exponent)
                item.setAmount(amt)
            }
            item.setComment(e.comment)

            for (const i in e.valuesMap) {
                item.getValuesMap().set(e.valuesMap[i][0], e.valuesMap[i][1])
            }

            items.push(item)
        })
    }
    ord.setItemsList(items)

    let services = []
    if (order.servicesList) {
        order.servicesList.forEach((e) => {
            let service = new OrderService()
            service.setId(e.id)
            service.setName(e.name)
            service.setDisplayName(e.displayName)
            service.setServiceTypeName(e.serviceTypeName)
            if (e.amount?.value) {
                let amt = new Money()
                amt.setValue(e.amount.value)
                amt.setCurrencyName(e.amount.currencyName)
                amt.setExponent(e.amount.exponent)
                service.setAmount(amt)
            }
            service.setComment(e.comment)
            services.push(service)
        })
    }
    ord.setServicesList(services)

    let discounts = []
    if (order.discountsList) {
        order.discountsList.forEach((e) => {
            let discount = new OrderDiscount()
            discount.setId(e.id)
            discount.setName(e.name)
            discount.setDisplayName(e.displayName)
            discount.setDiscountTypeName(e.discountTypeName)
            if (e.serviceName) discount.setServiceName(e.serviceName)
            if (e.sourceName) discount.setSourceName(e.sourceName)
            if (e.amount?.value) {
                let amt = new Money()
                amt.setValue(e.amount.value)
                amt.setCurrencyName(e.amount.currencyName)
                amt.setExponent(e.amount.exponent)
                discount.setAmount(amt)
            }
            discount.setComment(e.comment)
            discounts.push(discount)
        })
    }
    ord.setDiscountsList(discounts)

    return ord
}

const orderServiceType2Model = (instance) => {
    let result = new OrderServiceType()
    result.setName(instance.name)
    result.setCode(instance.code)
    result.setDisplayName(instance.displayName)
    result.setIsActive(instance.isActive)
    result.setIsRequired(instance.isRequired)
    result.setIsDefault(instance.isDefault)
    result.setOrderIndex(instance.orderIndex)
    result.setGroupDisplayName(instance.groupDisplayName)
    result.setIsApprovable(instance.isApprovable)
    return result
}

const orderDiscountType2Model = (instance) => {
    let result = new OrderDiscountType()
    result.setName(instance.name)
    result.setCode(instance.code)
    result.setDisplayName(instance.displayName)
    result.setIsActive(instance.isActive)
    return result
}

const API = {
    listOrders: (params) => {
        const request = new ListOrdersRequest()
        if (params?.page) {
            request.setPage(params.page)
        }
        if (params?.perPage) {
            request.setPerPage(params.perPage)
        }
        if (params?.sort) {
            request.setSort(params.sort)
        }
        if (params?.sortColumn) {
            request.setSortColumn(params.sortColumn)
        }
        if (params?.search) {
            request.setSearch(params.search)
        }
        if (params?.customerName) {
            request.setCustomerName(params.customerName)
        }
        if (params?.terminalName) {
            request.setTerminalName(params.terminalName)
        }
        if (params?.unpaidOnly) {
            request.setUnpaidOnly(params.unpaidOnly)
        }
        if (params?.status) {
            request.setStatus(params.status)
        }
        if (params?.isArchive) {
            request.setIsArchive(params.isArchive)
        }
        return new Promise((resolve, reject) => {
            client.listOrders(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                    reject(err)
                } else {
                    resolve(response.toObject())
                }
            })
        })
    },
    getCustomerOrders: (customerName) => {
        const request = new ListOrdersRequest()
        request.setCustomerName(customerName)
        return new Promise((resolve, reject) => {
            client.listOrders(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                    reject(err)
                } else {
                    resolve(response.toObject().ordersList)
                }
            })
        })
    },
    getOrder: (name) => {
        const request = new GetOrderRequest()
        request.setName(name)
        return new Promise((resolve, reject) => {
            client.getOrder(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                    reject(err)
                } else {
                    resolve(response.toObject())
                }
            })
        })
    },
    createOrder: (order) => {
        let ord = order2Model(order)
        const request = new CreateOrderRequest()
        request.setOrder(ord)

        const metadata = getMetadata()
        if (order?.requestId) {
            metadata["X-RequestId"] = order.requestId
        }
        return new Promise((resolve, reject) => {
            client.createOrder(request, metadata, (err, response) => {
                if (response == null) {
                    console.log(err)
                    reject(err)
                } else {
                    resolve(response.toObject())
                }
            })
        })
    },
    updateOrder: (order) => {
        let ord = order2Model(order)
        const request = new UpdateOrderRequest()
        request.setName(order.name)
        request.setOrder(ord)

        return new Promise((resolve, reject) => {
            client.updateOrder(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                    reject(err)
                } else {
                    resolve(response.toObject())
                }
            })
        })
    },
    deleteOrder: (name) => {
        const request = new DeleteOrderRequest()
        request.setName(name)

        return new Promise((resolve, reject) => {
            client.deleteOrder(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                    reject(err)
                } else {
                    resolve(response.toObject())
                }
            })
        })
    },

    moveToArchive: (order) => {
        const request = new MoveToArchiveRequest()
        request.setName(order.name)
        request.setIsArchive(order.isArchive)

        return new Promise((resolve, reject) => {
            client.moveToArchive(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                    reject(err)
                } else {
                    resolve(response.toObject())
                }
            })
        })
    },

    listOrderServiceTypes: () => {
        const request = new ListOrderServiceTypesRequest()
        return new Promise((resolve, reject) => {
            client.listOrderServiceTypes(
                request,
                getMetadata(),
                (err, response) => {
                    if (response == null) {
                        console.log(err)
                        reject(err)
                    } else {
                        resolve(response.toObject().orderServiceTypesList)
                    }
                },
            )
        })
    },
    getOrderServiceType: (name) => {
        const request = new GetOrderServiceTypeRequest()
        request.setName(name)
        return new Promise((resolve, reject) => {
            client.getOrderServiceType(
                request,
                getMetadata(),
                (err, response) => {
                    if (response == null) {
                        console.log(err)
                        reject(err)
                    } else {
                        resolve(response.toObject())
                    }
                },
            )
        })
    },
    createOrderServiceType: (orderServiceType) => {
        const ord = orderServiceType2Model(orderServiceType)
        const request = new CreateOrderServiceTypeRequest()
        request.setOrderServiceType(ord)

        return new Promise((resolve, reject) => {
            client.createOrderServiceType(
                request,
                getMetadata(),
                (err, response) => {
                    if (response == null) {
                        console.log(err)
                        reject(err)
                    } else {
                        resolve(response.toObject())
                    }
                },
            )
        })
    },
    updateOrderServiceType: (orderServiceType) => {
        const ord = orderServiceType2Model(orderServiceType)
        const request = new UpdateOrderServiceTypeRequest()
        request.setName(orderServiceType.name)
        request.setOrderServiceType(ord)

        return new Promise((resolve, reject) => {
            client.updateOrderServiceType(
                request,
                getMetadata(),
                (err, response) => {
                    if (response == null) {
                        console.log(err)
                        reject(err)
                    } else {
                        resolve(response.toObject())
                    }
                },
            )
        })
    },
    deleteOrderServiceType: (name) => {
        const request = new DeleteOrderServiceTypeRequest()
        request.setName(name)

        return new Promise((resolve, reject) => {
            client.deleteOrderServiceType(
                request,
                getMetadata(),
                (err, response) => {
                    if (response == null) {
                        console.log(err)
                        reject(err)
                    } else {
                        resolve(response.toObject())
                    }
                },
            )
        })
    },

    listOrderDiscountTypes: () => {
        const request = new ListOrderDiscountTypesRequest()
        return new Promise((resolve, reject) => {
            client.listOrderDiscountTypes(
                request,
                getMetadata(),
                (err, response) => {
                    if (response == null) {
                        console.log(err)
                        reject(err)
                    } else {
                        resolve(response.toObject().orderDiscountTypesList)
                    }
                },
            )
        })
    },
    getOrderDiscountType: (name) => {
        const request = new GetOrderDiscountTypeRequest()
        request.setName(name)
        return new Promise((resolve, reject) => {
            client.getOrderDiscountType(
                request,
                getMetadata(),
                (err, response) => {
                    if (response == null) {
                        console.log(err)
                        reject(err)
                    } else {
                        resolve(response.toObject())
                    }
                },
            )
        })
    },
    createOrderDiscountType: (orderDiscountType) => {
        const ord = orderDiscountType2Model(orderDiscountType)
        const request = new CreateOrderDiscountTypeRequest()
        request.setOrderDiscountType(ord)

        return new Promise((resolve, reject) => {
            client.createOrderDiscountType(
                request,
                getMetadata(),
                (err, response) => {
                    if (response == null) {
                        console.log(err)
                        reject(err)
                    } else {
                        resolve(response.toObject())
                    }
                },
            )
        })
    },
    updateOrderDiscountType: (orderDiscountType) => {
        const ord = orderDiscountType2Model(orderDiscountType)
        const request = new UpdateOrderDiscountTypeRequest()
        request.setName(orderDiscountType.name)
        request.setOrderDiscountType(ord)

        return new Promise((resolve, reject) => {
            client.updateOrderDiscountType(
                request,
                getMetadata(),
                (err, response) => {
                    if (response == null) {
                        console.log(err)
                        reject(err)
                    } else {
                        resolve(response.toObject())
                    }
                },
            )
        })
    },
    deleteOrderDiscountType: (name) => {
        const request = new DeleteOrderDiscountTypeRequest()
        request.setName(name)

        return new Promise((resolve, reject) => {
            client.deleteOrderDiscountType(
                request,
                getMetadata(),
                (err, response) => {
                    if (response == null) {
                        console.log(err)
                        reject(err)
                    } else {
                        resolve(response.toObject())
                    }
                },
            )
        })
    },
}

export default API
