// ** React Imports
import { lazy } from 'react'

const OrderCalendar = lazy(() => import('views/ordering/calendar'))
const OrderList = lazy(() => import('views/ordering/orders/list'))
const OrderAdd = lazy(() => import('views/ordering/orders/add'))
const OrderView = lazy(() => import('views/ordering/orders/view'))
const OrderEdit = lazy(() => import('views/ordering/orders/edit'))
const InvoiceAdd = lazy(() => import('views/financial/invoices/add'))
const PaymentAdd = lazy(() => import('views/financial/payments/add'))

const OrderingRoutes = [
  {
    element: <OrderCalendar />,
    path: '/calendar'
  },
  {
    element: <OrderAdd />,
    path: '/orders/-/add',
    meta: {
      resource: 'orders',
      action: 'add'
    }
  },
  {
    element: <PaymentAdd />,
    path: '/orders/:orderId/payments/-/add',
    meta: {
      resource: 'payments',
      action: 'add'
    }
  },
  {
    element: <InvoiceAdd />,
    path: '/orders/:orderId/invoices/-/add',
    meta: {
      resource: 'invoices',
      action: 'add'
    }
  },

  {
    element: <OrderView />,
    path: '/orders/:id/view',
    meta: {
      className: 'ecommerce-application',
      resource: 'orders',
      action: 'read'
    }
  },
  {
    element: <OrderEdit />,
    path: '/orders/:id/edit',
    meta: {
      className: 'ecommerce-application',
      resource: 'orders',
      action: 'modify'
    }
  },
  {
    element: <OrderList />,
    path: '/orders',
    meta: {
      resource: 'orders',
      action: 'read'
    }
  },
  {
    element: <OrderList />,
    path: '/archive',
    meta: {
      resource: 'archive',
      action: 'read'
    }
  }
]

export default OrderingRoutes
